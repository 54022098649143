<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-title>
          <span>Account</span>

          <v-spacer></v-spacer>

          <GoogleAccountAutoComplete
            v-model="selectedAccountID"
            @changed="accountChanged"
          ></GoogleAccountAutoComplete>

          <v-spacer></v-spacer>

          <v-autocomplete v-model="selectedLanguageID" :items="languages" :loading="loadingRefreshLanguages" item-text="language_iso2" item-value="languages_fk" label="Language" @change="fetchData" 
            prepend-icon="language">
          </v-autocomplete>

          <v-spacer></v-spacer>

        </v-card-title>

        <v-simple-table>
          <thead>
            <tr>
              <th>Update step</th>
              <th># found</th>
              <th># matched</th>
              <th># remaining</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.step">
              <td>{{ item.step }}</td>
              <td>{{ item.found_count }}</td>
              <td>{{ item.matched_count }}</td>
              <td>{{ item.remaining_count }}</td>
            </tr>        
          </tbody>
        </v-simple-table>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'KeywordCollectorViewRefreshLog',
  data () {
    return {
      loadingRefreshLanguages: false,
      selectedAccountID: 0,
      selectedLanguageID: 0,
      languages: [],
      lsSelectedAccount: 'keywordCollector_selectedAccount',
      items: [],
      logRecords: []
    }
  },

  computed: {
    accountLanguagesEndpoint () {
      return '/a/core/account-languages?ad_network=Google&xfields=languages_fk,language_iso2&xsort=language_iso2&xper_page=5000&account_id=' + this.selectedAccountID
    },

    dataEndpoint () {
      return '/a/core/keyword-ideas-update-log?account_id=' + this.selectedAccountID + '&languages_fk=' + this.selectedLanguageID
    }
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
  },

  methods: {
    accountChanged: function () {
      if (!this.selectedAccountID) {
        return
      }

      // save to local storage
      localStorage.setItem(this.lsSelectedAccount, JSON.stringify(this.selectedAccountID))

      this.getAccountLanguages()
    },

    fetchData () {
      if (!this.selectedAccountID) {
        return
      }
      this.$http.get(this.dataEndpoint).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    getAccountLanguages () {
      this.loadingRefreshLanguages = true

      this.$http.get(this.accountLanguagesEndpoint).then(resp => {
        if (resp.data.data) {
          this.languages = resp.data.data
          if (this.languages.length > 0) {
            this.selectedLanguageID = this.languages[0]['languages_fk']
            this.fetchData()
          }
        } else {
          this.languages = []
          this.selectedLanguageID = 0
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.accountLanguagesEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingRefreshLanguages = false)
    },
  },

  created: function () {

    // if present, use local selected account
    if (localStorage.getItem(this.lsSelectedAccount)) {
      this.selectedAccountID = JSON.parse(localStorage.getItem(this.lsSelectedAccount))
      this.getAccountLanguages()
    }
  },
}
</script>
